












































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import { PostBookContent } from "@/request/storehouse";
import { BookMuluText } from "@/utils/base";
@Component({
  components: {
    MainHeader,
    Empty,
  },
})
export default class Name extends Vue {
  private source: any = "";
  private fontSize: any = 16;
  private title: any = "";
  private id: any = "";
  private page: any = "";
  private currentZhangjie: any = "";
  private data: any = {};
  private get currentKu() {
    return this.$store.state.currentKu;
  }
  private getBookMuluText(item: any) {
    return BookMuluText(item);
  }
  private goMulu() {
    if (this.page == "search") {
      this.$router.push({
        path: "/main/storehouse/mulu",
        query: {
          id: this.data.book_id,
        },
      });
    } else {
      this.$router.back();
    }
  }
  private getData(isFirst?: any) {
    const params: any = {
      title_id: this.id,
    };
    PostBookContent(this, params).then((res: any) => {
      this.data = res;
      if (!this.currentZhangjie) {
        this.data.titles.forEach((ele: any, index: any) => {
          if (this.id == ele._id) {
            this.currentZhangjie = ele;
            this.currentZhangjie.index = index;
          }
        });
      }
    });
  }
  private goBefore() {
    if (this.currentZhangjie.index === 0) {
      this.$message.warning("已经到底啦！");
      return;
    }
    const index = this.currentZhangjie.index - 1;
    this.id = this.data.titles[index]._id;
    this.currentZhangjie = this.data.titles[index];
    this.currentZhangjie.index = index;
    this.getData();
  }
  private goNext() {
    if (this.currentZhangjie.index === this.data.titles.length - 1) {
      this.$message.warning("已经到底啦！");
      return;
    }
    const index = this.currentZhangjie.index + 1;
    this.id = this.data.titles[index]._id;
    this.currentZhangjie = this.data.titles[index];
    this.currentZhangjie.index = index;
    this.getData();
  }
  private mounted() {
    this.id = this.$route.query.id;
    this.title = this.$route.query.title;
    this.source = this.$route.query.source;
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    this.getData(true);
  }
}
